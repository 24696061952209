
.App {
  max-width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  color: white;
  background-image: linear-gradient(79deg, #7439db, #C66FBC 48%, #F7944D);
  overflow-x: hidden; /* Prevent horizontal scroll */
}


.auth-form-container, .login-form, .register-form {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 600px) {
  .auth-form-container {
    padding: 5rem;
    border: 1px solid white;
    border-radius: 10px;
    margin: 0.5rem;
  }
}

img {
  max-width: 100%; /* Ensures that the image is not wider than its container */
  height: auto; /* Keeps the aspect ratio of the image */
}

.icon-button {
  background: none; /* Removes default button background */
  border: none; /* Removes default button border */
  cursor: pointer; /* Changes the cursor to pointer when hovering over the button */
  vertical-align: middle; /* To align vertically */
  width: 16vw
}


.upload-icon {
  width: 14vw;
  height: 14vw;
  cursor: pointer; /* If you want to show pointer cursor on image hover */
}

.centeredField .cf-field {
  text-align:center;
}

.input-wrapper {
  display : flex;
  align-items: center;
  justify-content: center;

  width : 100vh;
  height:100%;
  text-align: center;
  margin-bottom: 1rem;
  flex-direction: column;
  
  
}

input[type="file"] {
  padding: 0.5rem;
  border-radius: 20px; /* Increase border-radius for a more rounded shape */
  cursor: pointer;
  font-size: 1rem;
}

/* Custom styles for the file input button */
input[type="file"]::-webkit-file-upload-button {
  padding: 0.5rm;
  border-radius: 20px; /* Rounded shape */
  background-color: white; /* Or any other color */
  color: #7439db; /* Or any other color */
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
  font-weight: bold;
  margin-bottom: 1rem;
}

/* Style adjustments for color input */
input[type="color"] {
  width: 4vw; /* Adjust size as needed */
  height: 4vw;
  border-radius: 50%; /* Fully rounded */
  border: none;
  vertical-align: middle; /* To align vertically */
  cursor: pointer;
  margin-right: 20px;
}

/* Style adjustments for color swatch for webkit browsers */
input[type="color"]::-webkit-color-swatch {
  border-radius: 50%; /* Fully rounded */
  border: none;
}

button {
  border: none;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
  color: #7439db;
}

canvas {
  max-width: 100%; /* remove fixed sizes if any */
  overflow: hidden;
  height: auto; /* remove fixed sizes if any */
}


.link-btn {
  background: none;
  color: white;
  text-decoration: underline;
}